.content {
  max-width: 800px;
  margin: auto;
}

.landing {
  padding: 1rem;
  background-color: #15232d;
}

.landing .subtitle {
  text-align: center;
  width: 100%;
}

.landing .title {
  text-align: center;
  color: #f69630;
}

.box {
  background-color: #183549;
  color: #fff;
  border: 1px solid #ffb454;
  margin-top: 1rem;
  padding: 1rem;
}


.landing .button {
  background-color: #ffb454;
  border-color: #f69630;
  color: #15232d;
}

.gif-container {
  min-height: 400px;
}

.text-content {
  margin: 1rem 1rem;
}

.text-content ul {
  margin: 1rem 1rem 1rem 4rem;
}

ul {
  list-style-type: circle;
}
