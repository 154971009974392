/* App palette */
:root {
  --font-family: "Source Code Pro", monospace;
  --color-primary: #ffb454;
  --color-primary-active: #ffc600;
  --color-primary-hover: #ffc600;
  --color-primary-bg: #15232d;
  --color-bg-container: #183549;
  --color-bg-layout: #15232d;
  --color-bg-elevated: #15232d;
  --color-text: rgba(255, 255, 255, 0.90);
  --color-text-secondary: #9ef68d;
  --color-text-tertiary: #9dffff;
  --color-text-quaternary: #f893fc;
  --color-border: #15232d;
  --color-border-secondary: #ffb454;
  --color-success: #5acd37;
  --color-warning: #ffc600;
  --color-error: #f16381;
  --color-info: #0087ff;
  --color-bg-base: #15232d;
}
