.advanced-prompt {
  padding: .5em 1em;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);
}

.advanced-sider {
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}
