.editable-prompt textarea {
  font-size: 20px;
  line-height: 1.4;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

.editable-prompt textarea:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
