.assisted-prompt {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffb454;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffc600;
}

.input-area {
  margin: 0.3rem 0.5rem 0 0.5rem;
}

.user-request {
  align-self: flex-start;
  max-width: 60vw;
  margin-bottom: .6em;
}

.server-response.prompt {
  align-self: flex-end;
  max-width: 60vw;
  min-width: 20vw;
  margin-right: 1.8em;
}

.server-response.images {
  margin-right: 1.8em;
}
