/* Navbar */

.navbar {
  position: sticky;
  top: 0;
  /* z-index: 99990; */
}

.nav-header {
  border-bottom: 0;
  padding: 0 4px 0 24px;
}

.logo {
  width: 100px;
  float: left;
  height: 64px;
}

.logo h3 {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
}

.navbar-menu {
  width: calc(100% - 150px);
  float: left;
}

.navbar-menu .ant-layout-header {
  padding: 0 20px;
}

.navbar-menu .ant-menu-horizontal {
  border-bottom: 0;
}

.navbar-menu .ant-menu-item {
  padding-bottom: 0px;
}

.navbar-menu .leftMenu {
  float: left;
}

.navbar-menu .rightMenu {
  float: right;
}

.menuButton {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 14px;
  display: none;
  background: none;
  margin-right: 10px;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

.username {
  display: none;
  margin-left: 8px;
}

@media (max-width: 767px) {
  .menuButton {
    display: block;
    margin-right: 10px;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo h3 {
    margin-left: -10px;
  }

  .ant-drawer-title>.brand-font {
    margin-bottom: 0;
  }

  .username {
    display: inline-block;
  }
}

/* Navbar End */
